// If you want to override variables do it here
@import "variables";

// Import styles with default layout.
// If you are going to use dark layout please comment next line
@import "~@coreui/coreui-pro/scss/coreui";

// Import styles with dark layout
// If you want to use dark layout uncomment next line
//@import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark";

// If you want to add something do it here
@import "custom";

// Spinkit
$spinkit-spinner-color: $body-color;
@import '~spinkit/scss/spinkit.scss';

@import '~css-star-rating/scss/star-rating';


.dashboard-monday .card { background-color: #fffdcd; }
.dashboard-tuesday .card { background-color: #fad9ea; }
.dashboard-wednesday .card { background-color: #bce7e0; }
.dashboard-thuesday .card { background-color: #fde8d3; }
.dashboard-friday .card { background-color: #ceeaf6; }
.dashboard-saturday .card { background-color: #e3daeb; }
.dashboard-sunday .card { background-color: #fbd3d4; }


body { font-family: "Kanit", sans-serif; }
.plaina:link { text-decoration: none; }
.plaina:visited { text-decoration: none; }
.plaina:hover { text-decoration: none; }
.plaina:active { text-decoration: none; }
.card-header { background-color: #20ade0 !important; color: white; font-size: 1.2rem;}
.news-cover {
  width: 126px;
}
.grey-bg { background-color: #badfec; }

.cmp-number { font-family: 'Courier New', Courier, monospace; }
.price-td { padding: 0.25rem !important; }
.img-zone {
  min-height: 140px;
  padding: 10px;
  display: flex;
  color: #717386;
  border: 2px dashed #717386;
  border-radius: 5px;
  overflow-x: auto;
}
.preview-img {
  object-fit: cover;
  width: 120px;
  height: 120px;
  border-radius: 5px;
  box-shadow: 4px 4px #88888888;
}
.close-preview {
  width: 20px;
  height: 20px;
  border-radius: 7px;
  float: right;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1000;
  margin: 4px;
  background-color: #eeeb;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}
.required { color: red; }

.pac-container {
  z-index: 1100 !important;
}

.wait-status {
  color: rgb(34, 107, 204);
}

.progress-status {
  color: rgb(249, 168, 37);
}

.completed-status {
  color: rgb(76, 175, 80);
}

.cancel-status {
  color: rgb(126, 126, 126);
}

.wait-status-bg {
  background-color: rgba(34, 107, 204, 0.2);
}

.progress-status-bg {
  background-color: rgba(249, 168, 37, 0.2);
}

.completed-status-bg {
  background-color: rgba(76, 175, 80, 0.2);
}

.cancel-status-bg {
  background-color: rgba(126, 126, 126, 0.2);
}

.legend-item {
  display: block;
  margin-right: 10px;
}

.legend-label {
  font-size: 14px;
  margin-right: 6px;
}

.nav-dropdown-items {
  margin-left: 10px !important;
}

.profile-item {
  color: white !important; /* sets the link color to white */
}

.profile-item:visited {
  color: white !important; /* sets the visited link color to white */
}

@media (max-width: 450px) {
  .dashboard-logo {
    display: none;
  }
  .dcard {
    padding: 0px 8px 8px 8px !important;
  }
  /* Styles to apply when the viewport is less than 450px wide */
}